<template>
    <!--新增对话框-->
    <el-dialog
            title="用户"
            :visible.sync="dialogVisible"
            width="900px"
            append-to-body
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :inline="true">
            <el-form-item label="注册时间" label-width="100">
                <el-date-picker
                        v-model="searchForm.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy年MM月dd日"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item >
            <el-form-item label="用户id" label-width="100">
                <el-input
                        v-model="searchForm.userId"
                        placeholder="用户id"
                        clearable>
                </el-input>
            </el-form-item >
            <el-form-item label="身份" label-width="100">
                <el-select v-model="searchForm.identity" clearable placeholder="选择身份">
                    <el-option label="货主" :value="2"></el-option>
                    <el-option label="司机" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getUserList" type="success"  >搜索</el-button>
                <el-button @click="allUserList" type="success"  >全选</el-button>
            </el-form-item>
        </el-form>
        <el-form  ref="editForm" label-width="100px" class="demo-editForm">
            <el-form-item label="选择用户">
                    <el-checkbox @change="cheng(item)"
                            v-for="item in user"
                            :key="item.userId"
                            :label="item.nickName"
                            v-model="item.checked">
                    </el-checkbox>
            </el-form-item>
            <el-form-item >
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[200, 250, 500, 1000,1500,2000]"
                        :current-page="current"
                        :page-size="size"
                        :total="total">
                </el-pagination>
            </el-form-item>
            <el-form-item  align="center">
                <el-button type="primary" @click="submitForm('editForm')" >确定</el-button>
                 <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>
        <GetCouponsRelGood ref="getCouponsRelGood" v-if="getCouponsStoreGoodsvisible" @refreshDataList="getStoreGoods"></GetCouponsRelGood>
    </el-dialog>
</template>

<script>
    import GetCouponsRelGood from "./GetCouponsRelGood";
    export default {
        name: "GetUsers",
        components:{
            GetCouponsRelGood
        },
        data(){
            return{
                searchForm:{},
                user:[],
                total: 0,
                size: 200,
                current: 1,
                dialogVisible:false,
                ids:[],
                couponsId:'',
                getCouponsStoreGoodsvisible:false,
            }
        },
        methods:{
            getList(){
                this.getCouponsStoreGoodsvisible=true;
                this.$nextTick(()=>{
                    this.$refs.getCouponsRelGood.init();
                })
            },
            handleSizeChange(val) {
                this.size = val
                this.getUserList()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getUserList()
            },
            getStoreGoods(item){
                this.searchForm.storeGoodsId=item.storeGoodsId
                this.searchForm.storeGoodsName= item.storeGoodsName
                this.$forceUpdate();
            },
            getUserList(relUserIdList){
                const loading = this.$loading({
                    lock: true,
                    text: '正在加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var startTimes='';
                var endTimes='';
                if(this.searchForm.createTime!=undefined){
                    startTimes=this.searchForm.createTime[0];
                    endTimes=this.searchForm.createTime[1];
                }
                this.searchForm.startTimes=startTimes
                this.searchForm.endTimes=endTimes;
                this.searchForm.couponsId=this.couponsId
                if (this.searchForm.identity==null||this.searchForm.identity==undefined||this.searchForm.identity==''){
                    this.searchForm.identity=2
                }
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.$axios.post("/admin/coupons/getUserList", this.searchForm).then(res=>{
                    this.size = res.data.size
                    this.current = res.data.current
                    this.total = res.data.total
                    this.user=res.data.records
                    if (this.ids!=null&&this.ids.length>0&&this.user.length>0){
                        this.user.forEach(item=>{
                            this.ids.forEach(model=>{
                                if (item.userId==model){
                                    item.checked=true
                                    return
                                }
                            })
                        })
                    }
                    loading.close()
                })
            },
            resetForm(formName) {
                this.dialogVisible = false
                this.editForm = {}
                this.user=[]
                this.ids=[]
                this.searchForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            allUserList(){
                this.user.forEach(item=>{
                    item.checked=true
                    this.ids.push(item.userId)
                })
                var newList = Array.from(new Set(this.ids))  //数组去重
                this.ids = newList
            },
            submitForm(item) {
                const loading = this.$loading({
                    lock: true,
                    text: '正在保存中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios.post("/admin/coupons/updateCouponUser", {couponsId:this.couponsId,userIdList:this.ids}).then(res=>{
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose:() => {
                            this.$emit('refreshDataList')
                        }
                    });
                    this.dialogVisible = false
                    this.resetForm()
                    loading.close()
                })
            },
            cheng(item){
                if (item.checked){
                    this.ids.push(item.userId)
                }else {
                    this.ids.map((item1,index)=>{
                        if(item.userId==item1){
                            this.ids.splice(index,1);
                        }
                    });
                }
            },
            init(id){
                this.dialogVisible=true;
                this.couponsId=id
                this.getUserList()
            },
        }
    }
</script>

<style scoped>
</style>
