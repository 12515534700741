<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="创建时间" label-width="100">
                <el-date-picker
                        v-model="searchForm.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy年MM月dd日"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="名称" label-width="100">
                <el-input
                        v-model="searchForm.couponsName"
                        placeholder="名称"
                        clearable>
                </el-input>
            </el-form-item >
            <el-form-item label="优惠规则类型" label-width="100">
                <el-select v-model="searchForm.rulesType" clearable placeholder="选择优惠规则类型">
                    <el-option label="满减" value="2"></el-option>
                    <el-option label="无门槛" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getCoupons" type="success"  >搜索</el-button>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="addUpdateCouponsButton(null)" v-if="hasAuth('sys:coupons:save')">添加优惠券</el-button>
            </el-form-item>
        </el-form>

        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
                @selection-change="handleSelectionChange">


            <el-table-column
                    label="名称"
                    align="center"
                    prop="couponsName"
                    width="200">
            </el-table-column>
            <el-table-column
                    prop="isOnframe"
                    align="center"
                    label="是否上架"
                    width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isOnframe === 0" type="success">上架</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isOnframe === 1" type="info">下架</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="rulesType"
                    align="center"
                    label="优惠规则类型"
                    width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.rulesType === 2" type="success">满减</el-tag>
                    <el-tag size="small" v-else-if="scope.row.rulesType === 1" type="info">无门槛</el-tag>
                </template>
            </el-table-column>
             <el-table-column
                    prop="startTime"
                    align="center"
                    width="200"
                    label="优惠券有效开始时间">
            </el-table-column>
             <el-table-column
                    prop="endTime"
                    align="center"
                    width="200"
                    label="优惠券有效结束时间">
            </el-table-column>
<!--            <el-table-column-->
<!--                    prop="receivingTime"-->
<!--                    align="center"-->
<!--                    width="200"-->
<!--                    label="优惠券领取时间">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                    label="优惠券数量"-->
<!--                    align="center"-->
<!--                    prop="amount"-->
<!--                    width="150"-->
<!--            ></el-table-column>-->
            <el-table-column
                    align="center"
                    label="满减条件"
                    prop="satisfyFrom"
                    width="120"
            ></el-table-column>
            <el-table-column
                    align="center"
                    label="抵扣值"
                    prop="satisfyTo"
                    width="120">
            </el-table-column>
             <el-table-column
                    prop="status"
                    align="center"
                    label="数据状态"
                    width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.status === 0" type="success">有效</el-tag>
                    <el-tag size="small" v-else-if="scope.row.status === 1" type="info">失效</el-tag>
                </template>
            </el-table-column>

            <el-table-column
                    prop="createTime"
                    align="center"
                    width="200"
                    label="创建时间">
            </el-table-column>
            <el-table-column
                    prop="icon"
                    align="center"
                    width="360"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="success" plain slot="reference" @click="updCouponsIsOnframe(scope.row)" v-if="hasAuth('sys:coupons:updCouponsIsOnframe')">
                        {{scope.row.isOnframe=='0' ? "下架" : "上架"}}</el-button>
                    <el-button type="primary" plain @click="addUpdateCouponsButton(scope.row.couponsId)" v-if="hasAuth('sys:coupons:update')&&scope.row.isOnframe==1">编辑</el-button>
                    <el-button type="primary" plain @click="getUsersList(scope.row.couponsId)" v-if="scope.row.newcomerCoupon==2">选择用户</el-button>
                    <template v-if="hasAuth('sys:coupons:delete')">
                        <el-popconfirm title="确定删除该优惠券吗？" @confirm="delHandle(scope.row.couponsId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total">
        </el-pagination>
        <AddUpdateCoupons ref="addUpdateCoupons" v-if="addUpdateCouponsvisible" @refreshDataList="getCoupons"></AddUpdateCoupons>
        <SelectUsers ref="getCouponsUsers" v-if="getCouponsUserssvisible" @refreshDataList="getUsersList(scope.row.couponsId)"></SelectUsers>
    </div>
</template>

<script>
    import AddUpdateCoupons from "./AddUpdateCoupons";
    import SelectUsers from "./SelectUsers";
    export default {
        name: "Coupons",
        components:{
            AddUpdateCoupons,SelectUsers
        },
        data(){
            return{
                searchForm:{},
                delBtlStatu:true,
                tableData:[],
                multipleSelection:[],
                total: 0,
                size: 10,
                current: 1,
                addUpdateCouponsvisible: false,
                getCouponsUserssvisible: false,
            }
        },
        created() {
            this.getCoupons();
        },
        methods:{
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.delBtlStatu = val.length == 0
            },

            handleSizeChange(val) {
                this.size = val
                this.getCoupons()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getCoupons()
            },
            //获取资讯列表
            getCoupons() {
                var startTimes='';
                var endTimes='';
                if(this.searchForm.createTime!=undefined){
                    startTimes=this.searchForm.createTime[0];
                    endTimes=this.searchForm.createTime[1];
                }
                this.searchForm.startTimes=startTimes
                this.searchForm.endTimes=endTimes;
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.$axios.post("/admin/coupons/getCoupons", this.searchForm).then(res => {
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.pages
                    this.total = res.data.total
                })
            },
            //选择用户
            getUsersList(couponsId){
                this.getCouponsUserssvisible=true;
                this.$nextTick(()=>{
                    this.$refs.getCouponsUsers.init(couponsId);
                })
            },
            //删除资讯
            delHandle(id) {
                this.$axios.post("/admin/coupons/delCoupons", {couponsId:id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose:() => {
                            this.getCoupons()
                        }
                    });
                })
            },

            //下架、上架商城商品
            updCouponsIsOnframe(model){
                this.$confirm('操作需谨慎：此操作将是该优惠券'+(model.isOnframe===0?'下架':'上架')+'，是否继续?', '提示',
                    {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() =>
                {
                    this.$axios.post("/admin/coupons/updCouponsIsOnframe", {couponsId:model.couponsId}).then(res => {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success',
                            onClose: () => {
                                this.getCoupons()
                            }
                        });
                    })
                })
            },

            addUpdateCouponsButton(id){
                this.addUpdateCouponsvisible=true;
                this.$nextTick(()=>{
                    this.$refs.addUpdateCoupons.init(id);
                })
            },

        }
    }
</script>

<style scoped>
    .el-button{
        padding: 0 10px;
        height: 35px;
        line-height:35px;
        font-size:15px;
        margin-right: 10px;
    }
</style>
